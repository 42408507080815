import * as firebase from 'firebase/app';
import {
  Auth,
  User,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import {
  DocumentData,
  Firestore,
  Query,
  QueryConstraint,
  QuerySnapshot,
  Timestamp,
  UpdateData,
  WhereFilterOp,
  WriteBatch,
  collection,
  deleteDoc,
  doc,
  endAt,
  getDoc as firebaseGetDoc,
  setDoc as firebaseSetDoc,
  updateDoc as firebaseUpdateDoc,
  getDocs,
  getFirestore,
  initializeFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAt,
  where,
  writeBatch,
} from 'firebase/firestore';
import { Functions, getFunctions, httpsCallable } from 'firebase/functions';
import {
  FirebaseStorage,
  StorageReference,
  UploadMetadata,
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { Observable } from 'rxjs';

import { firebaseConfig } from '../1/constant';
import { sleep } from '../1/util';

export type CallableName =
  | 'callGetProductIdList'
  | 'callGetProductNameList'
  | 'callSyncFavoriteList'
  | 'callKakaoFriendtalk'
  | 'callKakaoAlimtalk'
  | 'callSyncKakaoTalkResult'
  | 'callGetSeoulStores'
  | 'callSetUserStatus'
  | 'callAnalyzeStoreCategoryProducts'
  | 'callSendMobileMessage'
  | 'callSyncMobileMessageResult'
  | 'callCreateInvoiceDetail'
  | 'callSyncProductPriceTrend'
  | 'callSetProductWeeklyPrice';

export type WHERE = [string, WhereFilterOp, any];

export interface QueryOptions {
  sortKey: string;
  orderBy: 'asc' | 'desc';
  startValue?: any;
  endValue?: any;
}

/**
 * timestamp 필드로 되어 있는 값은 받으면 seconds와 nanoseconds 필드로 변환된다.
 * 이 값을 이용해서 다시 firestore.Timestamp()로 복구한다.
 * 조사 필드는 _time으로 시작하는 4개다.
 */
const recoverTimestamp = (doc: UpdateData<any>) => {
  ['Create', 'Update', 'Set', 'Merge']
    .map((command) => `_time${command}`)
    .forEach((key) => {
      const value = doc[key];
      if (value) {
        if (value instanceof Object && value.seconds !== undefined && value.nanoseconds !== undefined) {
          doc[key] = new Timestamp(value.seconds, value.nanoseconds).toDate();
        }
      }
    });

  return doc;
};

export class FirebaseManager {
  // 지금은 단일 firebase project만 사용중이다.
  // 추후 복수의 사용이 필요할 때 객체로 전환하자.
  private static instance: FirebaseManager;
  private auth: Auth;
  private firestore: Firestore;
  private storage: FirebaseStorage;
  private storageRef: StorageReference;
  private functions: Functions;

  // 최대 500개로 되어 있지만
  // serverTimestamp() 는 1이 더 추가된다고 한다.
  // 그래서 안전하게 250으로 한다.
  // refer: https://github.com/firebase/firebase-admin-node/issues/456
  private readonly MaxBatchNum = 150;
  private batch?: WriteBatch;
  private batchCount = 0;

  public static getInstance() {
    if (FirebaseManager.instance === undefined) {
      FirebaseManager.instance = new FirebaseManager();
    }

    return FirebaseManager.instance;
  }

  constructor() {
    const app = firebase.initializeApp(firebaseConfig);
    this.auth = getAuth();
    initializeFirestore(app, {
      ignoreUndefinedProperties: true,
    });
    this.firestore = getFirestore();
    this.storage = getStorage();
    this.storageRef = ref(this.storage, process.env.REACT_APP_STORAGE_BUCKET_DIR);
    this.functions = getFunctions(app);
  }

  /**
   * @param path #, [, ], * 또는 ?는 사용하면 안된다.
   */
  private getStorageRef(path: string) {
    return ref(this.storageRef, path);
  }

  public getCallable<REQUEST, RESPONSE>(callableName: CallableName) {
    return httpsCallable<REQUEST, RESPONSE>(this.functions, callableName);
  }

  public uploadTask(path: string, file: File, metadata?: UploadMetadata) {
    if (!path || !file) {
      throw new TypeError('path나 file이 없는 것 같아요');
    }

    const fileRef = this.getStorageRef(path);
    return uploadBytesResumable(fileRef, file, metadata);
  }

  public deleteFile(path: string) {
    if (!path) {
      throw new TypeError('path가 없는 것 같아요');
    }

    const fileRef = this.getStorageRef(path);
    return deleteObject(fileRef);
  }

  public getDownloadURL(ref: StorageReference) {
    return getDownloadURL(ref);
  }

  public batchStart() {
    this.batch = writeBatch(this.firestore);
    this.batchCount = 0;
  }

  public async batchEnd() {
    const fnName = 'batchEnd';

    if (this.batch === undefined) {
      console.error(`[${fnName}] No this.batch. Run batchStart() first.`);
      return false;
    }

    if (this.batchCount > 0) {
      console.info(`[${fnName}] batchCount == ${this.batchCount} => Run batch.commit()`);
      this.batchCount = 0;
      await this.batch.commit();
    } else {
      console.info(`[${fnName}] batchCount == ${this.batchCount} => NOOP`);
    }

    return undefined;
  }

  /**
   * 지정한 collection에 doc을 추가한다
   * documennt Id는 자동생한다.
   *
   * bMerge의 경우에 doc이 다음과 같다면
   * ```
   * k1: {
   *  k2: v1
   * }
   * ```
   * k1을 전체 업데이트 하는 것이 아니라 k1.k2만을 업데이트 하거나 추가한다는 사실을 명심해야 한다.
   * k1.k3와 같은 키가 있다면 유지되는 것이다. path의 개념으로 이해해야 한다.
   *
   * 특정 필드를 삭제하려면 다음과 같은 특별한 값을 지정해야 한다.
   * `deletingKey: admin.firestore.FieldValue.delete()`
   *
   * refer : https://cloud.google.com/nodejs/docs/reference/firestore/0.20.x/DocumentReference
   *
   * - options.idAsField = false: false => id doc의 id로 사용, true => id는 doc의 필드를 가리킨다.
   * - options.bMerge = true: true이면 지정한 필드만 업데이트한다.
   * - options.addMetadata = true: _id, _timeUpdate 필드를 자동으로 생성한다.
   * - options.bBatch = false: batch에 추가한다. batchStart(), batchEnd()와 함께 사용한다.
   */
  public async setDoc(
    collectionPath: string,
    id: string | undefined,
    docData: any,
    options?: {
      idAsField?: boolean;
      bMerge?: boolean;
      addMetadata?: boolean;
      bBatch?: boolean;
    }
  ) {
    const fnName = 'setDoc';

    const { idAsField = false, bMerge = true, addMetadata = true, bBatch = false } = options ?? {};

    if (idAsField && (id === undefined || docData[id] === undefined)) {
      throw new TypeError(`'${id}' field does not exist in doc`);
    }

    const firestore = this.firestore;
    const collectionRef = collection(firestore, collectionPath);

    const docRef =
      id === undefined
        ? doc(collectionRef)
        : idAsField
        ? doc(firestore, `${collectionPath}/${docData[id]}`)
        : doc(firestore, `${collectionPath}/${id}`);

    recoverTimestamp(docData);

    // metadata 추가
    const metadata = addMetadata ? { _timeUpdate: Timestamp.now(), _id: docRef.id } : {};

    try {
      if (this.batch && bBatch) {
        this.batch.set(docRef, { ...docData, ...metadata }, { merge: bMerge });
        await this.batchAdded();
      } else {
        await FirebaseManager.runFirestoreAPI(fnName, collectionPath, () =>
          firebaseSetDoc(docRef, { ...docData, ...metadata }, { merge: bMerge })
        );
      }
    } catch (error) {
      console.error(error);
      console.error(error);
      throw error;
    }

    return docRef.id;
  }

  /**
   * 동일 document path에 이미 존재하는 경우에는 에러
   *
   * @params collectionPath
   * @params id
   * @params doc
   * @params options
   * - idAsField = false: false => id doc의 id로 사용, true => id는 doc의 필드를 가리킨다.
   * - addMetadata = true: _id, _timeCreate 필드를 자동으로 생성한다.
   * - bBatch = false: batch에 추가한다. batchStart(), batchEnd()와 함께 사용한다.
   */
  public async createDoc(
    collectionPath: string,
    id: string | undefined,
    docData: DocumentData,
    options?: {
      idAsField?: boolean;
      addMetadata?: boolean;
      bBatch?: boolean;
    }
  ) {
    const fnName = 'createDoc';
    const { idAsField = false, addMetadata = true, bBatch = false } = options ?? {};

    if (idAsField && (id === undefined || docData[id] === undefined)) {
      throw new TypeError(`'${id}' field does not exist in doc`);
    }

    const firestore = this.firestore;
    const collectionRef = collection(firestore, collectionPath);

    const docRef =
      id === undefined
        ? doc(collectionRef)
        : idAsField
        ? doc(firestore, `${collectionPath}/${docData[id]}`)
        : doc(firestore, `${collectionPath}/${id}`);

    recoverTimestamp(docData);

    // metadata 추가
    const withMetadata = addMetadata ? { _timeCreate: Timestamp.now(), _id: docRef.id } : {};
    const uploadData = { ...docData, ...withMetadata };

    const getDocData = await firebaseGetDoc(docRef);

    if (getDocData.exists()) {
      console.error(`[${fnName}] ${collectionPath}:${JSON.stringify(uploadData)}, 이미 존재하는 Doc`);
      throw new Error('이미 존재하는 doc');
    }

    try {
      // 혹시 이미 존재하는 doc이면 merge 해준다.
      if (this.batch && bBatch) {
        this.batch.set(docRef, uploadData, { merge: true });
        await this.batchAdded();
      } else {
        await FirebaseManager.runFirestoreAPI(fnName, collectionPath, () =>
          firebaseSetDoc(docRef, uploadData, { merge: true })
        );
      }
    } catch (error) {
      console.error(error);
      console.error(`[${fnName}] 예외 발생, error = ${JSON.stringify(error, undefined, 2)}`);
      throw error;
    }

    return docRef.id;
  }

  /**
   * 이미 document가 존재해야 한다.
   * document 전체를 변경하는 것이 아니라
   * 겹치지 않는 최상위 필드는 유지한다.
   *
   * - options.idAsField = false: false => id doc의 id로 사용, true => id는 doc의 필드를 가리킨다.
   * - options.addMetada = true: _id, _timeUpdate 필드를 자동으로 생성한다.
   * - options.bBatch = false: batch에 추가한다. batchStart(), batchEnd()와 함께 사용한다.
   */
  public async updateDoc(
    collectionPath: string,
    id: string,
    docData: UpdateData<any>,
    options?: {
      idAsField?: boolean;
      addMetadata?: boolean;
      bBatch?: boolean;
    }
  ) {
    const fnName = 'updateDoc';

    const { idAsField = false, addMetadata = true, bBatch = false } = options ?? {};

    if (id === undefined) {
      throw new TypeError('id must exist');
    }

    const firestore = this.firestore;
    const docRef = idAsField
      ? doc(firestore, `${collectionPath}/${docData[id]}`)
      : doc(firestore, `${collectionPath}/${id}`);

    recoverTimestamp(docData);

    // metadata 추가
    if (addMetadata) {
      docData._id = docRef.id;
      docData._timeUpdate = Timestamp.now();
    }

    try {
      if (bBatch) {
        this.batch?.update(docRef, docData);
        await this.batchAdded();
      } else {
        await FirebaseManager.runFirestoreAPI(fnName, collectionPath, () => firebaseUpdateDoc(docRef, docData));
      }
    } catch (error) {
      console.error(`[${fnName}] 예외 발생, error = ${JSON.stringify(error, undefined, 2)}`);
      throw error;
    }

    return docRef.id;
  }

  /**
   * doc를 읽어서 응답한다.
   * 못 찾으면 Promise<undefined>를 리턴한다.
   *
   * @param docPath ex) 'unifiedOrder/1234'
   */
  public async getDoc<T>(docPath: string) {
    const fnName = 'getDoc';

    try {
      const firestore = this.firestore;
      const docRef = doc(firestore, docPath);
      const documentSnapshot = await FirebaseManager.runFirestoreAPI(fnName, docPath, () => firebaseGetDoc(docRef));

      // exists는 document가 존재하고 내용도 있다는 뜻이다.
      if (documentSnapshot.exists()) {
        const doc = documentSnapshot.data() as T;
        // 발생할 확률이 0이지만 혹시나 해서 추가해 본다.
        if (doc === undefined) {
          throw new Error('No doc');
        }
        return doc;
      } else {
        return undefined;
      }
    } catch (error) {
      console.error(docPath, error);
      console.error(`[${fnName}] 예외 발생, error = ${JSON.stringify(error, undefined, 2)}`);
      throw error;
    }
  }

  /**
   * 해당 collection의 조건에 맞는 docs 배열을  리턴한다.
   */
  public async getDocsArrayWithWhere<T>(
    collectionPath: string,
    wheres: WHERE[],
    options?: {
      // selectField?: string[], // Node AdminSDK만 가능
      sortKey: string; // ex. '_timeCreate'
      orderBy: 'asc' | 'desc';
      startValue?: any;
      endValue?: any;
      limit?: number;
    }
  ) {
    const fnName = 'getDocsArrayWithWhere';

    try {
      const querySnapshot = await this.querySnapshotWithWhere<T>(fnName, collectionPath, wheres, options);

      return querySnapshot.docs.map((queryDocumentSnapshot) => queryDocumentSnapshot.data());
    } catch (error) {
      console.error(`[${fnName}] 예외 발생, error = ${JSON.stringify(error, undefined, 2)}`);
      throw error;
    }
  }

  /**
   * 해당 collection의 조건에 맞는 docs를 리턴한다.
   *
   * 응답 형태는 docId를 key로 하는 Object가 된다.
   */
  public async getDocsWithWhere<T>(
    collectionPath: string,
    wheres: WHERE[],
    options?: {
      // selectField?: string[], // Node AdminSDK만 가능
      sortKey: string; // ex. '_timeCreate'
      orderBy: 'asc' | 'desc';
      limit?: number;
    }
  ) {
    const fnName = 'getDocsWithWhere';

    try {
      const querySnapshot = await this.querySnapshotWithWhere<T>(fnName, collectionPath, wheres, options);

      return querySnapshot.docs.reduce((docs, queryDocumentSnapshot) => {
        docs[queryDocumentSnapshot.id] = queryDocumentSnapshot.data();
        return docs;
      }, {} as DocumentData);
    } catch (error) {
      console.error(`[${fnName}] 예외 발생, error = ${JSON.stringify(error, undefined, 2)}`);
      throw error;
    }
  }

  /**
   * doc를 삭제한다.
   *
   * @param docPath ex) 'unifiedOrder/1234'
   */
  public async deleteDocument(docPath: string, options?: { bBatch: boolean }) {
    const { bBatch = false } = options ?? {};
    const firestore = this.firestore;
    const docRef = doc(firestore, docPath);
    try {
      if (this.batch && bBatch) {
        this.batch.delete(docRef);
        await this.batchAdded();
      } else {
        await deleteDoc(docRef);
      }
    } catch (error) {
      console.error(`deleteDocument error: ${docPath}`);
      throw error;
    }
  }

  /**
   * 간단한 where 조건과 orderBy 조건으로 조회한 valueChanges Observable을 반환
   *
   * ex
   * - observeCollection('unifiedOrder', [['site', '==', 'gk-kangnam']])
   * - observeCollection('unifiedOrder', [['site', '==', 'gk-kangnam']], { sortKey: 'orderDate', orderBy: 'desc'})
   * - observeCollection('unifiedOrder', [['site', '==', 'gk-kangnam']], { sortKey: 'orderDate', orderBy: 'desc', startValue: '...'})
   * - observeCollection('unifiedOrder', [['site', '==', 'gk-kangnam']], { sortKey: 'orderDate', orderBy: 'desc', startValue: '...', endValue: '...'})
   *
   * @param collectionPath ex) 'unifiedOrder'
   * @param wheres 조건 배열
   * @param options
   * - sortKey: 정렬할 필드명
   * - orderBy: 정렬(오름차, 내림차)
   * - startValue, endValue: 조회 시작~끝 조건
   */
  public observeCollection<T>(
    collectionPath: string,
    wheres: WHERE[],
    options?:
      | {
          sortKey: string;
          orderBy: 'asc' | 'desc';
          startValue?: any;
          endValue?: any;
        }
      | {
          sortKey: string;
          orderBy: 'asc' | 'desc';
          startValue?: any;
          endValue?: any;
        }[]
  ) {
    const fnName = 'observeCollection';
    let optionQueryConstraints: QueryConstraint[] = [];
    if (options && Array.isArray(options)) {
      optionQueryConstraints = options.map((option) => this.defaultQueryConstraints(option)).flat();
    } else {
      optionQueryConstraints = this.defaultQueryConstraints(options);
    }

    return new Observable<T[]>((subscriber) => {
      const queryConstraints: QueryConstraint[] = [
        ...optionQueryConstraints,
        ...wheres.map((_where: WHERE) => where(_where[0], _where[1], _where[2])),
      ];

      const firestore = this.firestore;
      const collectionRef = collection(firestore, collectionPath);
      const queryResult = query(collectionRef, ...queryConstraints) as Query<T>;

      const unsubscribeSnapshot = onSnapshot(
        queryResult,
        (querySnapshot) => {
          const docs = querySnapshot.docs.map((doc) => doc.data() as T);
          subscriber.next(docs);
        },
        (error) => {
          subscriber.error(error);
          console.error(collectionPath, error);
          throw error;
        },
        () => {
          subscriber.complete();
        }
      );

      const unsubscribe = () => {
        unsubscribeSnapshot();
        console.info(`[${fnName}] unsubscribe: ${collectionPath}`);
      };

      return unsubscribe;
    });
  }

  /**
   * 하나의 도큐먼트만 관찰한다.
   *
   * @param documentPath ex) 'user/uid'
   */
  public observeDoc<T>(documentPath: string) {
    const fnName = 'observeDoc';

    return new Observable<T | undefined>((subscriber) => {
      const firestore = this.firestore;
      const docRef = doc(firestore, documentPath);
      const unsubscribeSnapshot = onSnapshot(
        docRef,
        (querySnapshot) => {
          if (!querySnapshot.exists()) {
            subscriber.next(undefined);
            return;
          }
          const doc = querySnapshot.data() as T;
          subscriber.next(doc);
        },
        (error) => {
          subscriber.error(error);
          throw error;
        },
        () => {
          subscriber.complete();
        }
      );

      const unsubscribe = () => {
        unsubscribeSnapshot();
        console.info(`[${fnName}] unsubscribe: ${documentPath}`);
      };

      return unsubscribe;
    });
  }

  public createUserWithEmailAndPassword(email: string, password: string) {
    const auth = this.auth;
    return createUserWithEmailAndPassword(auth, email, password);
  }

  public signIn(email: string, password: string) {
    const auth = this.auth;
    return signInWithEmailAndPassword(auth, email, password);
  }

  public sendPasswordResetEmail(email: string) {
    const auth = this.auth;
    return sendPasswordResetEmail(auth, email);
  }

  public signOut() {
    return signOut(this.auth);
  }

  public getCurrentUser() {
    return this.auth.currentUser;
  }

  public observeAuthState() {
    const fnName = 'observeAuthState';

    return new Observable<User | null>((subscriber) => {
      const unsubscribeSnapshot = onAuthStateChanged(
        this.auth,
        (user) => {
          subscriber.next(user);
        },
        (error) => {
          subscriber.error(error);
          throw error;
        },
        () => {
          subscriber.complete();
        }
      );

      const unsubscribe = () => {
        unsubscribeSnapshot();
        console.info(`[${fnName}] unsubscribe: AuthState`);
      };

      return unsubscribe;
    });
  }

  public getFirestoreRandomId(collectionPath: string) {
    const firestore = this.firestore;
    const collectionRef = collection(firestore, collectionPath);
    const docRef = doc(collectionRef);
    const id = docRef.id;

    return id;
  }

  public getFirestoreCollectionRef(collectionPath: string) {
    const firestore = this.firestore;
    return collection(firestore, collectionPath);
  }

  /**
   *  firestore api 호출이 실패하는 경우 최소 n(maxTry)번 재시도를 한다.
   */
  private static async runFirestoreAPI<T>(caller: string, collectionOrDocPath: string, api: () => Promise<T>) {
    const maxTry = 3;
    let countTry = 0;

    while (countTry < maxTry) {
      try {
        // 없으면 만들고 있으면 덮어쓴다.
        countTry++;
        if (countTry > 1) {
          // console.error(`[${caller}:${collectionOrDocPath}] countTry = ${countTry}, diffTime = ${diffTimestamp(caller)}`);
          console.error(`[${caller}:${collectionOrDocPath}] countTry = ${countTry}`);
        }

        // DEADLINE_EXCEEDED 방지를 위해
        // await timeMargin(caller, 500);
        return api();
        // await docRef.set(doc, { merge: bMerge });
        // 성공한 경우에는 루프를 빠져나간다.
      } catch (error: any) {
        // 마지막 try에서의 throw 처리는 아래에서 수행한다.
        // 2: "details": "Stream removed"
        // 4: DEADLINE_EXCCEDED
        // 10: "details": "Too much contention on these documents. Please try again."
        // 13: "details": ""
        // 13: "details": "An internal error occurred."
        // 14: "details": "The service is temporarily unavailable. Please retry with exponential backoff."
        // 14: "details": "Transport closed"
        // 14: "details": "GOAWAY received"
        if (countTry < maxTry && [2, 4, 10, 13, 14].includes(error.code)) {
          // console.error(`[${caller}] error at countTry = ${countTry}, error = ${JSON.stringify(error, undefined, 2)}`);
          await sleep(2000);
          continue;
        }

        // console.error(`[${caller}] Give Up. Should the page be reloaded???. countTry = ${countTry}, error = ${JSON.stringify(error, undefined, 2)}`);
        throw error;
      }
    }

    // typescript에서 return undefined로 인지하지 못 하도록
    throw new Error('Unexpected reach');
  }

  /**
   * this.batch에 추가한 후에 반드시 실행한다.
   */
  private async batchAdded() {
    const fnName = 'batchAdded';

    if (this.batch === undefined) {
      console.error(`[${fnName}] No this.batch. Run batchStart() first.`);
      return false;
    }

    this.batchCount++;

    if (this.batchCount >= this.MaxBatchNum) {
      console.info(`[${fnName}] batchCount == ${this.batchCount} => Run batch.commit()`);
      await this.batch.commit();

      // 비웠으니 다시 시작한다.
      this.batchStart();
    }

    return undefined;
  }

  /**
   * getDocsWithWheres와 getDocsArrayWithWheres의 공통 부분
   */
  private querySnapshotWithWhere<T>(
    fnName: string,
    collectionPath: string,
    wheres: WHERE[],
    options?: {
      // selectField?: string[], // Node AdminSDK만 가능
      sortKey: string; // ex. '_timeCreate'
      orderBy: 'asc' | 'desc';
      startValue?: any;
      endValue?: any;
      limit?: number;
    }
  ) {
    const optionLimit = options?.limit;

    // 1. wheres & sort를 적용
    const queryConstraints: QueryConstraint[] = [
      ...wheres.map((_where) => where(_where[0], _where[1], _where[2])),
      ...this.defaultQueryConstraints(options),
    ];

    // 2. limit을 적용
    if (optionLimit) {
      queryConstraints.push(limit(optionLimit));
    }

    // 3. 조회
    const firestore = this.firestore;
    const collectionRef = collection(firestore, collectionPath);
    const qeuryResult = query(collectionRef, ...queryConstraints);

    return FirebaseManager.runFirestoreAPI(
      fnName,
      collectionPath,
      () => getDocs(qeuryResult) as Promise<QuerySnapshot<T>>
    );
  }

  private defaultQueryConstraints(options?: {
    sortKey: string;
    orderBy: 'asc' | 'desc';
    startValue?: any;
    endValue?: any;
  }): QueryConstraint[] {
    if (options?.startValue && options?.endValue) {
      // 조회 start~end 조건이 모두 있는 경우
      const startValueConstraint = Array.isArray(options.startValue)
        ? options.orderBy === 'asc'
          ? startAt(...options.startValue)
          : endAt(...options.startValue)
        : options.orderBy === 'asc'
        ? startAt(options.startValue)
        : endAt(options.startValue);
      const endValueConstraint = Array.isArray(options.endValue)
        ? options.orderBy === 'asc'
          ? endAt(...options.endValue)
          : startAt(...options.endValue)
        : options.orderBy === 'asc'
        ? endAt(options.endValue)
        : startAt(options.endValue);
      return [orderBy(options.sortKey, options.orderBy), startValueConstraint, endValueConstraint];
      // const startValueForAsc = Array.isArray(options.startValue) ? startAt(...options.startValue) : startAt(options.startValue);
      // const endValueForAsc = Array.isArray(options.endValue) ? endAt(...options.endValue) : endAt(options.endValue);
      // const startValueForDesc = Array.isArray(options.startValue) ? endAt(...options.startValue) : endAt(options.startValue);
      // const endValueForDesc = Array.isArray(options.endValue) ? startAt(...options.endValue) : startAt(options.endValue);
      // return [
      //   orderBy(options.sortKey, options.orderBy),
      //   options.orderBy === 'asc' ? startValueForAsc : startValueForDesc,
      //   options.orderBy === 'asc' ? endValueForAsc : endValueForDesc
      // ];
    } else if (options?.startValue) {
      // 조회 시작 조건만 있는 경우
      return [
        orderBy(options.sortKey, options.orderBy),
        options.orderBy === 'asc' ? startAt(options.startValue) : endAt(options.startValue),
      ];
    } else if (options?.sortKey) {
      // orderBy 조건만 있는 경우
      return [orderBy(options.sortKey, options.orderBy)];
    }
    // 정렬 없는 경우
    return [];
  }
}
