import {
  AuditOutlined,
  BarChartOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  EnvironmentOutlined,
  FileSearchOutlined,
  MessageOutlined,
  NotificationOutlined,
  ProfileOutlined,
  RocketOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  UnorderedListOutlined,
  UserOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { FC, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { UserRole } from './lib/2/schema';

import DashboardPage from './pages/DashboardPage';
import DeliveryPage from './pages/DeliveryPage';
import KakaoMessagePage from './pages/KakaoMessagePage';
import LogPage from './pages/LogPage/LogPage';
import NotificationPage from './pages/NotificationPage';
import OrderPage from './pages/OrderPage';
import ProductPage from './pages/ProductPage/ProductPage';
import PromotionPage from './pages/PromotionPage/PromotionPage';
import PurchaseOrderPage from './pages/PurchaseOrderPage';
import SettingsPage from './pages/SettingsPage';
import SettleAccountsPage from './pages/SettleAccountsPage';
import SettlementPage from './pages/SettlementPage/SettlementPage';
import SigninPage from './pages/SigninPage';
import StatsPage from './pages/StatsPage/StatsPage';
import StoreCategoryPage from './pages/StoreCategoryPage';
import StoreIssuePage from './pages/StoreIssuePage';
import StorePage from './pages/StorePage';
import UserPage from './pages/UserPage';

// import StorePage from './pages/StorePage';
// import SupplierPage from './pages/SupplierPage';
import AppLayout from './components/Layout/AppLayout';
import Loading from './components/Loading/Loading';

import ChatRoutes from './modules/chat/routes/ChatRoutes';
import { useAuth } from './stores/auth-context';

// TODO:  리펙터링 필요
export const siteMap: Record<
  string,
  {
    title: string;
    page: FC<any>;
    icon?: React.ReactNode;
    key: string;
    role?: UserRole[];
    children?: any[];
  }
> = {
  dashboard: {
    title: '대시보드',
    page: DashboardPage,
    key: '/dashboard',
    icon: <DashboardOutlined />,
  },
  order: {
    title: '주문 관리',
    page: OrderPage,
    key: '/order',
    icon: <AuditOutlined />,
  },
  issue: {
    title: '이슈 관리',
    page: StoreIssuePage,
    key: '/issue',
    icon: <WarningOutlined />,
    children: [
      {
        label: '이슈 목록',
        icon: null,
        key: '/issue/list',
      },
      {
        label: '출력',
        icon: null,
        key: '/issue/print',
      },
    ],
  },
  settlement: {
    title: '정산 관리',
    page: SettlementPage,
    key: '/settlement',
    role: ['admin'],
    icon: <DollarCircleOutlined />,
    children: [
      {
        label: '청구서',
        icon: null,
        key: '/settlement/invoice',
        role: ['admin'],
      },
      {
        label: '매장별 상세',
        icon: null,
        key: '/settlement/store',
        role: ['admin'],
      },
      {
        label: '지난 내역',
        icon: null,
        key: '/settlement/history',
        role: ['admin'],
      },
      {
        label: '청구서 메시지',
        icon: null,
        key: '/settlement/message',
        role: ['admin'],
      },
    ],
  },
  'settle-accounts': {
    title: '(구) 정산 관리',
    page: SettleAccountsPage,
    key: '/settle-accounts',
    icon: <DollarCircleOutlined />,
  },
  product: {
    title: '상품 관리',
    page: ProductPage,
    icon: <ShoppingCartOutlined />,
    key: '/product',
    children: [
      {
        label: '상품 목록',
        icon: null,
        key: '/product/list',
      },
      {
        label: '상품 수정/추가',
        icon: null,
        key: '/product/upload',
      },
      {
        label: '재고 관리',
        icon: null,
        key: '/product/stock',
      },
      {
        label: '재고 이슈',
        icon: null,
        key: '/product/stock-issue',
      },
      {
        label: '상품 가격 맵핑',
        icon: null,
        key: '/product/price-mapping',
      },
      {
        label: '경매 가격 동향',
        icon: null,
        key: '/product/market-price-trend',
      },
      {
        label: '경쟁사 가격 동향',
        icon: null,
        key: '/product/competitor-price-trend',
      },
    ],
  },
  'store-category': {
    title: '업종 관리',
    page: StoreCategoryPage,
    key: '/store-category',
    icon: <UnorderedListOutlined />,
  },
  purchase: {
    title: '발주 & 재고 관리',
    page: PurchaseOrderPage,
    icon: <ProfileOutlined />,
    key: '/purchase',
    children: [
      {
        label: '발주서 미리보기',
        icon: null,
        key: '/purchase/preview',
      },
      {
        label: '발주서',
        icon: null,
        key: '/purchase/dashboard',
      },
      {
        label: '매입처 관리',
        icon: null,
        key: '/purchase/supplier',
      },
      {
        label: '발주 & 재고 현황',
        icon: null,
        key: '/purchase/product-request',
      },
      {
        label: '재고 위치 관리',
        icon: null,
        key: '/purchase/product-bin-location',
      },
    ],
  },
  user: {
    title: '사용자 관리',
    page: UserPage,
    icon: <UserOutlined />,
    key: '/user',
    children: [
      {
        label: '모든 사용자',
        icon: null,
        key: '/user/all',
      },
      {
        label: '사용자 상세',
        icon: null,
        key: '/user/detail',
      },
    ],
  },
  store: {
    title: '매장 관리',
    page: StorePage,
    icon: <ShopOutlined />,
    key: '/store',
  },
  delivery: {
    title: '배송 관리',
    page: DeliveryPage,
    icon: <EnvironmentOutlined />,
    key: '/delivery',
    children: [
      {
        label: '배송 동선',
        icon: null,
        key: '/delivery/route',
        role: ['admin'],
      },
      {
        label: '파트너 관리',
        icon: null,
        key: '/delivery/partners-user',
      },
      {
        label: '배송지 관리',
        icon: null,
        key: '/delivery/spot',
      },
      {
        label: '배송지 지도',
        icon: null,
        key: '/delivery/map',
      },
      {
        label: '패킹표',
        icon: null,
        key: '/delivery/packing-sheet',
      },
      {
        label: '거래명세서',
        icon: null,
        key: '/delivery/invoice',
      },
      {
        label: '기타 인쇄물',
        icon: null,
        key: '/delivery/packing-info-print',
      },
      {
        label: '물류 업무 히스토리',
        icon: null,
        key: '/delivery/task-history',
      },
      {
        label: '배송 실적',
        icon: null,
        key: '/delivery/performance/table',
        role: ['admin'],
      },
    ],
  },
  notification: {
    title: '알림',
    page: NotificationPage,
    icon: <NotificationOutlined />,
    key: '/notification',
    children: [
      {
        label: '알림 발송',
        icon: null,
        key: '/notification/dashboard',
      },
      {
        label: '전송 결과(카톡제외)',
        icon: null,
        key: '/notification/list',
      },
    ],
  },
  stats: {
    title: '통계',
    page: StatsPage,
    icon: <BarChartOutlined />,
    key: '/stats',
    children: [
      {
        label: '월간 주문 통계',
        icon: null,
        key: '/stats/monthly-order?tab=stacked-bar&year=2024',
      },
      {
        label: '일일 주문 통계',
        icon: null,
        key: '/stats/daily-order?tab=line&year=2024',
      },
      {
        label: '상품 가격 차트',
        icon: null,
        key: '/stats/product',
      },
    ],
  },
  log: {
    title: '로그 관리',
    page: LogPage,
    icon: <FileSearchOutlined />,
    key: '/log',
    role: ['admin'],
    children: [
      {
        label: '앱 로그',
        icon: null,
        key: '/log/commerce',
      },
      {
        label: '검색 로그',
        icon: null,
        key: '/log/search',
      },
      {
        label: '어드민 로그',
        icon: null,
        key: '/log/console',
      },
    ],
  },
  message: {
    title: '메시지 발송 결과',
    page: KakaoMessagePage,
    icon: <MessageOutlined />,
    key: '/message',
    children: [
      {
        label: '알림톡',
        icon: null,
        key: '/message/alimtalk/installment-bill',
      },
      {
        label: '친구톡',
        icon: null,
        key: '/message/friendtalk/daily-price-list',
      },
      {
        label: '문자',
        icon: null,
        key: '/message/sms',
      },
    ],
  },
  promotion: {
    title: '프로모션',
    page: PromotionPage,
    icon: <RocketOutlined />,
    role: ['admin'],
    key: '/promotion',
  },
  settings: {
    title: '설정',
    page: SettingsPage,
    icon: <SettingOutlined />,
    role: ['admin'],
    key: '/settings',
  },
  // playground: {
  //   title: '설정',
  //   page: PlaygroundPage,
  //   icon: <PlayCircleOutlined />,
  // },
  login: { title: '로그인', page: SigninPage, key: '/login', icon: undefined },
};

const RouteElements = (role?: UserRole) => {
  if (role === undefined) return null;
  return (
    <>
      <Route path='/login' element={<Navigate to={'/'} replace />} />
      {Object.entries(siteMap)
        .filter(([, value]) => value.icon !== undefined && (value.role === undefined || value.role.includes(role)))
        .map(([path, site]) => (
          <Route key={path} path={`/${path}/*`} element={<site.page title={site.title} />} />
        ))}
    </>
  );
};

const Router: FC = () => {
  const { authUser, authLoading, isLoggedIn, user } = useAuth();
  const location = useLocation();
  const [initialPath, setInitialPath] = useState<string | null>(null);
  const initialized = authUser !== null;

  console.log('initialized', initialized);

  // 최초 동작시 2번의 render를 진행한다.
  // 1. loading true render
  // 2. loading false render
  useEffect(() => {
    if (!initialized && initialPath === null) {
      // 최초 초기화가 안된 상태에서 접근한 주소를 기억해야한다.
      // 무한 루프를 막기위해 로그인 페이지는 제외한다.
      if (!['/', '/login'].includes(location.pathname)) {
        const path = location.pathname.split('/')[1];
        if (siteMap[path]) {
          setInitialPath(location.pathname);
        }
      }
    }
  }, [initialPath, initialized, location.pathname]);

  return (
    <>
      {authLoading && <Loading title='잠시만 기다려주세요.' />}
      <Routes>
        <Route element={<AppLayout />}>
          {/* 로그인 상태에 따른 리다이렉트 */}
          <Route path='/' element={<Navigate to={isLoggedIn ? initialPath ?? '/dashboard' : '/login'} replace />} />
          {!isLoggedIn && <Route path='/login' element={initialized ? <SigninPage /> : null} />}
          {/* 그 외 로그인이 필요한 페이지들 */}
          {isLoggedIn && RouteElements(user?.role)}
        </Route>

        {/* AppLayout 없이 채팅 URL로 라우팅 */}
        <Route path='/chat/*' element={<ChatRoutes />} />

        {/* 기본 루트 경로로 리다이렉트 */}
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    </>
  );
};

export default Router;
