import { add, differenceInWeeks, format, getISOWeek, getWeek, isDate, parseISO, set, startOfYear } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { ko } from 'date-fns/locale';
import dayjs, { Dayjs } from 'dayjs';
import { Timestamp } from 'firebase/firestore';

/**
 * @param addDays 0: 오늘, 1: 내일, -1: 어제
 * @returns
 */
export const getDateWithoutTime = (addDays?: number) => {
  const nowDate = addDays ? add(new Date(), { days: addDays }) : new Date();
  return new Date(nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate());
};

/**
 * 지역설정과 상관없이 한국 시간을 반환한다.
 */
export function getKoreaDate() {
  const timestamp = Timestamp.now();
  return toZonedTime(timestamp.toDate(), 'Asia/Seoul');
}

type DateForamt =
  | 'yyyyMM'
  | 'yyMMdd'
  | 'MM.dd'
  | 'M.d'
  | 'L월 d일 HH:mm'
  | 'HH:mm:ss'
  | 'yyyy-MM-dd'
  | "yyyy-MM-dd'T'HH:mm:ss+0900"
  | "yyyy-MM-dd'T'00:00:00+0900"
  | "yyyy-MM-dd'T'23:59:59+0900"
  | 'L월 d일(EEEEEE) HH:mm:ss'
  | 'L월 dd일(EEEEEE) HH:mm:ss'
  | 'yy년 L월 d일(EEEEEE)'
  | 'L.dd(EEEEEE) HH:mm:ss'
  | 'yyyy-MM-dd HH:mm:ss'
  | 'yyyy. M. d'
  | 'yyyyMMdd'
  | 'yyyy년 L월 dd일'
  | 'y년 L월'
  | 'L월 d일';
export const formatDate = (date: Date | string, dateFormat: DateForamt, locale = ko) =>
  format(isDate(date) ? (date as Date) : new Date(date), dateFormat, { locale });

/**
 * 현 시점의 주문일자 문자열을 생성한다.
 * @param date
 * @returns '2023-02-06T20:00:00+0900'
 */
export const getOrderDate = (date?: Date) => format(date ?? Date.now(), "yyyy-MM-dd'T'HH:mm:ss+0900");

/**
 * @param orderDate '2023-02-06T20:00:00+0900'
 * @returns 11월 2일(금) 20:00:11
 */
export const orderDateFormat01 = (orderDate: string) =>
  format(new Date(orderDate), 'L월 d일(EEEEEE) HH:mm:ss', { locale: ko });

/**
 * @param orderDate '2023-02-06T20:00:00+0900'
 * @returns '2018-02-06 20:00:00'
 */
export const orderDateFormat02 = (orderDate: string) =>
  format(new Date(orderDate), 'yyyy-MM-dd HH:mm:ss', { locale: ko });

/**
 * @param orderDate Date
 * @returns '2023-02-06T00:00:00+0900'
 */
export const orderDateFormat03 = (orderDate?: Date) =>
  format(orderDate ? new Date(orderDate) : new Date(), "yyyy-MM-dd'T'HH:mm:ss+0900");

/**
 *
 * @param orderDate '2023-02-06T20:00:00+0900'
 * @returns '2022. 1. 3'
 */
export const orderDateFormat04 = (orderDate: string) => format(new Date(orderDate), 'yyyy. M. d', { locale: ko });

/**
 * @param orderDate
 * @returns '1월'
 */
export const orderDateFormat05 = (orderDate: string) => format(new Date(orderDate), 'L월', { locale: ko });

/**
 * @param date
 * @returns 'y년 L월'
 */
export const orderDateFormat06 = (orderDate: string) => format(new Date(orderDate), 'yy년 L월', { locale: ko });

/**
 * @param date
 * @returns 'L월d일'
 */
export const dateFormat06 = (date?: Date) => format(date ? new Date(date) : new Date(), 'L월d일', { locale: ko });

/**
 * @param date
 * @returns 'yyyy. MM. dd.'
 */
export const dateFormat07 = (date?: Date) => format(date ? new Date(date) : new Date(), 'yyyy. MM. dd', { locale: ko });

/**
 * @param date
 * @returns 'Y년 L월 d일 EEEE'
 */
export const dateFormat08 = (date?: Date) =>
  format(date ? new Date(date) : new Date(), 'yy년 L월 d일(EEEEEE)', { locale: ko });

/**
 * @param date
 * @returns 'yy-MM-dd.'
 */
export const dateFormat09 = (date?: Date) => format(date ? new Date(date) : new Date(), 'yy-MM-dd', { locale: ko });

/**
 *
 * @param date
 * @returns 'yyyy-MM-dd HH:mm'
 */
export const dateFormat10 = (date?: Date) =>
  format(date ? new Date(date) : new Date(), 'yyyy-MM-dd HH:mm', { locale: ko });

/**
 * @param orderDate '2023-02-06T20:00:00+0900'
 * @returns 'yyyy. M. d.'
 */
export const dateFormat11 = (date: string) => format(new Date(date), 'yyyy. M. d', { locale: ko });

export type RangeEventValue = [Dayjs | null, Dayjs | null] | null;
export const getDefaultDates = (from = 0, to = 7): [Dayjs, Dayjs] => {
  const now = dayjs(getDateWithoutTime());
  const right = dayjs(now).add(to, 'day');
  const left = dayjs(now).subtract(from, 'day');
  return [left, right];
};
export const getDefaultDate = (addDays?: number): Dayjs => {
  const now = dayjs(getDateWithoutTime(addDays));
  return now;
};

/**
 * 클라이언트의 현재 시간에 따라 예상 배송일을 반환한다.
 */
export const getDefaultDeliveryDate = () => {
  const now = new Date();
  const hour = now.getHours();
  /**
   * 배송중인 시간에는 현재 일자를, 배송중인 시간이 아닌경우 다음날을 반환한다.
   */
  return getDefaultDate(hour < 9 ? 0 : 1);
};

/**
 * 시작과 끝 날짜가 같은 경우 해당 일의 데이터를 포함할 수 있도록
 * 00시와 23시 59분 59초로 시간을 설정한다.
 */
export const datesToStringDateWhere = (left: Date, right: Date): [string, string] => {
  const leftDate = format(left, "yyyy-MM-dd'T'00:00:00+0900");
  const rightDate = format(right, "yyyy-MM-dd'T'23:59:59+0900");
  return [leftDate, rightDate];
};

/**
 * 시작과 끝 날짜가 같은 경우 해당 일의 데이터를 포함할 수 있도록
 * 00시와 23시 59분 59초로 시간을 설정한다.
 */
export const dayjsesToStringDateWhere = (left: Dayjs, right: Dayjs): [string, string] => {
  const leftDate = format(new Date(left.toDate()), "yyyy-MM-dd'T'00:00:00+0900");
  const rightDate = format(new Date(right.toDate()), "yyyy-MM-dd'T'23:59:59+0900");
  return [leftDate, rightDate];
};

export const dayjsToStringDateWhere = (date: Dayjs): string => {
  const whereDate = format(new Date(date.toDate()), "yyyy-MM-dd'T'00:00:00+0900");
  return whereDate;
};

export const dayjsesToDateWhere = (left: Dayjs, right: Dayjs): [Date, Date] => {
  const leftDate = left.toDate();
  const rightDate = right.toDate();
  return [leftDate, rightDate];
};

export const dayjsesToDateStringForSeoulStore = (left: Dayjs, right: Dayjs): [string, string] => {
  const leftDate = left.toDate();
  const rightDate = right.toDate();
  return [format(leftDate, 'yyyyMMdd'), format(rightDate, 'yyyyMMdd')];
};

export const dayOfWeekAsString = (dayIndex: number) => {
  return ['일', '월', '화', '수', '목', '금', '토'][dayIndex];
};

/**
 * 주어진 날짜가 몇 주차인지 반환한다.
 */
export const getWeeksInMonthStartMonday = (date: Date) => {
  // 해당 날짜가 속한 주차 계산
  const firstDayOfYear = startOfYear(date);
  const diffWeeks = differenceInWeeks(date, firstDayOfYear) + 1;
  return String(diffWeeks);
};

/** 현재 월의 가장 첫 날을 가져온다. */
export const getThisMonthFirstDate = () => {
  const now = new Date();
  const firstDate = new Date(now.getFullYear(), now.getMonth(), 1);
  return firstDate;
};

export const RESERVED_RECEIVE_HOUR = 7;

/**
 * 현재 시간이 지정한 시간보다 이전인지 확인한다.
 */
export const isHourBefore = (at: number) => {
  const currentHour = new Date().getHours();
  return currentHour < at;
};

/**
 * 새벽 알림 수신을 꺼리는 고객인 경우 발송 요청시간을 설정한다.
 */
export const getKakaoTalkRequestOptions = () => {
  // 00시 ~ 07시 사이에 발송된 메시지는 모두 아침 7로 발송 시간을 맞춘다.
  const isDawn = isHourBefore(RESERVED_RECEIVE_HOUR);
  return isDawn ? dateFormat10(set(new Date(), { hours: RESERVED_RECEIVE_HOUR, minutes: 0, seconds: 0 })) : undefined;
};

/**
 * n개월 전부터 현재까지의 날짜를 배열로 반환한다.
 */
export const getDaysForMonths = (range: number) => {
  const dates = [];
  const today = new Date();
  const months = new Date(today.setMonth(today.getMonth() - range));

  for (const d = months; d <= new Date(); d.setDate(d.getDate() + 1)) {
    dates.push(new Date(d).toISOString().split('T')[0]);
  }

  return dates;
};

/**
 * (오늘이) 몇 주차인지 반환한다.
 */
export const getWeekStartedMonday = (date?: Date) => {
  return getWeek(date ? date : new Date(), {
    weekStartsOn: 1,
  });
};

/**
 * (오늘이) 몇 년도의 몇 주차인지 반환한다.
 */
export const getYearWeek = (date: Date) => {
  return format(date ? date : new Date(), 'yyyy-ww', {
    weekStartsOn: 1,
  });
};

/**
 * 특정 연도의 총 주차 수를 구하는 함수
 */
export const getTotalWeeksOfYear = (year: number) => {
  // 해당 연도의 12월 28일 날짜 객체 생성
  const date = parseISO(`${year}-12-28`);
  return getISOWeek(date);
};

/**
 * 특정 연도의 주차 수를 계산하는 함수
 */
export const calcWeek = (year: number, currentWeek: number, adjustment: number) => {
  const afterAdjustment = currentWeek + adjustment;
  const totalWeeks = getTotalWeeksOfYear(year);
  const prevYear = year - 1;
  const totalLastYearWeeks = getTotalWeeksOfYear(prevYear);

  // 조정값이 총 주차 수보다 큰 경우
  if (Math.abs(adjustment) > 52) {
    throw new Error('조정값이 너무 큽니다.');
  }

  // 0 또는 0보다 작은경우
  if (afterAdjustment < 1) {
    // ex) afterAdjustment: -1, totalWeeks: 52 = 51주차
    return {
      year: prevYear,
      week: totalLastYearWeeks + afterAdjustment,
    };
  }
  // 현재 연도의 총 주차 수보다 큰 경우
  else if (afterAdjustment > totalWeeks) {
    // ex) afterAdjustment: 55, totalWeeks: 52 = 3주차
    return {
      year: year + 1,
      week: afterAdjustment - totalWeeks,
    };
  }

  // 그 외의 경우
  return {
    year,
    week: afterAdjustment,
  };
};

/**
 * 현재 주차에서 n주 전까지의 주차를 반환한다.
 */
export const getPrevWeeksFromNow = (year: number, week: number, range: number) => {
  const weeks = [
    {
      year,
      week,
    },
  ];
  for (let i = 1; i <= range; i++) {
    const { year: newYear, week: newWeek } = calcWeek(year, week, i * -1);
    weeks.push({ year: newYear, week: newWeek });
  }
  return weeks;
};

/**
 * 사용자 친화적인 시간을 출력합니다.
 */
export const timeToFamiliarNotation = (date: Date | string) => {
  const givenTime = dayjs(isDate(date) ? (date as Date) : new Date(date));
  const now = dayjs();

  // 두 시간 사이의 차이 계산
  const diffInMinutes = now.diff(givenTime, 'minute');
  const diffInHours = now.diff(givenTime, 'hour');
  const diffInDays = now.diff(givenTime, 'day');

  if (diffInMinutes < 60) {
    return diffInMinutes < 1 ? '지금' : `${diffInMinutes}분 전`;
  } else if (diffInHours < 24) {
    return `${diffInHours}시간 전`;
  } else {
    return `${diffInDays}일 전`;
  }
};
